import * as React from "react";
import logo from "../img/logo.svg";
import { Flex, Box } from "rebass";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <Flex container direction="row" justify="center" alignItems="center">
            <Box textAlign="center" fontSize={'0.8em'} p={3} width={1 / 3}>
              <span style={{ marginLeft: "10px", textAlign: "center" }}>
                İbrahim TOPÇU
              </span>
              <br />
              <span style={{ marginLeft: "10px", textAlign: "center" }}>
                <a href="tel:+905335412355" height="5em">
                  05335412355
                </a>
              </span>
            </Box>
            <Box textAlign="center" p={3} width={1 / 3}>
              <img
                src={logo}
                alt="Topçu Otomotiv"
                style={{ width: "14em", height: "5em" }}
              />
            </Box>
            <Box textAlign="center" fontSize={12} p={3} width={1 / 3}>
              <span style={{ marginLeft: "10px", textAlign: "center" }}>
                Murat TOPÇU
              </span>
              <br />
              <span style={{ marginLeft: "10px", textAlign: "center" }}>
                <a href="tel:+905335412355">05337211132</a>
              </span>
            </Box>
          </Flex>
        </div>
      </footer>
    );
  }
};

export default Footer;
